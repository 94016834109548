@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wdth,wght@125,619&display=swap");

body {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgb(2, 0, 36) 0%,
    rgba(9, 9, 121, 1) 32%,
    rgba(0, 210, 255, 1) 100%
  );
}

.App {
  color: white;
  width: calc(100vw -48px);
  min-height: 100vh;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 16px 0 16px;
  display: grid;
  grid-template-rows: min-content auto min-content;
}

/* BIO CONTAINER */
.BioContainer {
  margin-top: 50px;
  padding: 20px;
  text-align: center;
  background-color: transparent;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
}

.BioContainer img {
  margin-top: -48px;
  border-radius: 50%;
  border: 3px solid rgb(255, 255, 255);
  box-shadow: 0 0 32px rgb(255, 255, 255);
  width: 110px;
  height: 110px;
}

.BioContainer > .username {
  font-family: "Encode Sans", sans-serif;
}

/* ITEM CONTAINER */
.ItemsContainer {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  row-gap: 16px;
  column-gap: 16px;
}

.LinkItem {
  padding: 10px;
  background-color: transparent;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  transition: 0.25s ease-out;
}
@media only screen and (min-width: 800px) {
  .ItemsContainer {
    grid-template-columns: 1fr 1fr;
  }
  .LinkItem {
    padding: 20px 0 20px 20px;
  }
}

.LinkItem img {
  margin-right: 10px;
  margin-left: 10px;
  width: 40px;
  height: auto;
  vertical-align: middle;
}

.LinkItem p {
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
  color: white;
  margin-left: 5px;
}

.LinkItem:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

/* FOOTER CONTAINER */
.FooterContainer {
  height: 40px;
  margin-top: 35px;
  text-align: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  font-size: small;
  color: rgb(93, 93, 93);
  background-color: transparent;
  background-color: rgba(0, 0, 17, 0.833);
}
